<template>
	<div>
		<!-- 商家绑定银行卡 -->
		<div class="queryBox mb20">
			<el-input size="mini" placeholder="请输入商家号" v-model="business_no" clearable class="mr20" style="width: 200px"></el-input>
			<!-- <el-input size="mini" placeholder="请输入商家名" v-model="business_name" clearable class="mr20" style="width: 200px"></el-input> -->
			<el-cascader v-model="carder_id" class="mr20" :show-all-levels="false" placeholder="搬砖工" size="mini" :options="options"
			 :props="{ checkStrictly: true,expandTrigger:'hover',emitPath: false,value:'id',label:'name' }" clearable @change="agentChang"></el-cascader>
			<el-button size="mini" type="primary" :icon="disLoading?'el-icon-loading':'el-icon-search'" @click="getData" :disabled="disLoading">搜索</el-button>
		</div>
		<div class="flexX flexTop">
			<div class="flex1">
				<table class="dataTable" width="100%" v-loading="loading1">
					<thead>
						<tr>
							<th colspan="5" class="textCenter">可用搬砖工</th>
						</tr>
						<tr>

							<th class="textCenter">搬砖工</th>
							<th class="textCenter" style="width: 60px;">总卡数</th>
							<th class="textCenter" style="width: 60px;">未绑定商家卡数</th>
							<th class="textCenter">余额</th>
							<th class="textCenter">操作</th>

						</tr>
					</thead>
					<tbody :style="`max-height: ${height}px;`">
						<tr v-for="item in tableData1" v-if="tableData1 && tableData1.length > 0">
							<td class="textCenter">{{item.name}}</td>
							<td class="textCenter" style="width: 60px;">{{item.total_card_count}}</td>
							<td class="textCenter" style="width: 60px;">{{item.total_unbind_count}}</td>
							<td class="textCenter">{{item.used_money | tofixed}}</td>
							<td class="textCenter">
								<el-button size="mini" @click="chooseCarder(item)">选择</el-button>
							</td>

						</tr>
						<tr class="nodata" v-if="!tableData1 || tableData1.length === 0">
							<td colspan="5"> 暂无数据</td>
						</tr>
					</tbody>
				</table>
				<div class="flexX  pageBox" :class="total1>pageSize1?'mt10':''">
					<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange1" @current-change="handleCurrentChange1"
					 :current-page="page1" :page-sizes="pageSizesArr1" :page-size="pageSize1" layout="total, sizes, prev, pager, next, jumper"
					 :total="total1"></el-pagination>
				</div>
			</div>
			<div class="flex1" style="padding: 0 10px;">

				<table class="dataTable" width="100%" v-loading="loading2">
					<thead>
						<tr>
							<th colspan="8" class="textCenter">
								<span class="mr20">搬砖工可用账号</span>
								<el-button size="mini" type="primary" @click="addCard">加卡</el-button>
							</th>
						</tr>
						<tr>
							<th colspan="7" class="textCenter">
								<span class="mr20">筛选:</span>
								<el-input class="mr20" size="mini" style="width: 180px;" placeholder="请输入姓名" v-model="cardName"></el-input>
								<el-button size="mini" type="primary" :icon="disLoading3?'el-icon-loading':'el-icon-search'" @click="getDatatable4" :disabled="disLoading3">搜索</el-button>
								
							</th>
						</tr>
						<tr>

							<th class="textCenter" style="width: 115px;">银行卡信息</th>
							<th class="textCenter">短信在线状态</th>
							<th class="textCenter">监控状态</th>
							<th class="textCenter">启停状态</th>
							<!-- <th>测试状态</th> -->
							<th class="textCenter">余额</th>
							<th class="textCenter">实际余额</th>
							<th class="textCenter">已绑定商家</th>
							<th class="textCenter">操作</th>

						</tr>
					</thead>
					<tbody :style="`max-height: ${height2}px;`">
						<tr v-for="(item,index) in tableData2" v-if="tableData2 && tableData2.length > 0 && index < tableData2.length-2">
							<td class="textCenter" style="width: 115px;">
								<div v-if="index<tableData2.length-2">
									{{item.true_name}} <br> {{item.card.substring(0,3)}}***{{item.card.substring(item.card.length-4,item.card.length)}} <br> {{item.bank}}
								</div>
								<div v-if="index>=tableData2.length-2">{{item.all_parent_name}}</div>
							</td>
							<td class="textCenter">
								<div v-if="index<tableData2.length-2">
								<span v-if="item.phone_type == 2" :class="item.telephone_is_online == 1?'csuccess':item.telephone_is_online == 0?'cwarning':'cdanger'">{{item.telephone_is_online == 1?'在线':item.telephone_is_online == 0?'离线':'掉线'}}</span>
								</div>
							</td>
							<td class="textCenter">
								<div v-if="index<tableData2.length-2">
								<span :class="item.is_online == 1?'csuccess':item.is_online == 0?'cwarning':'cdanger'">{{item.is_online == 1?'在线':item.is_online == 0?'离线':'掉线'}}</span>
								</div>
							</td>
							<td class="textCenter">
								<div v-if="index<tableData2.length-2">
								<span :class="item.state == 0?'csuccess':'cdanger'">{{item.state == 0?'开启 ':'停用 '}}</span>
								</div>
							</td>
							<td class="textCenter">{{item.sys_quota | tofixed}}</td>
							<td class="textCenter">{{item.true_quota | tofixed}}</td>
							<td class="textCenter">
							<div v-if="index<tableData2.length-2">{{item.business_name}}</div>
							</td>
							<td class="textCenter">
								<div v-if="index<tableData2.length-2"><el-checkbox :disabled="item.business_name!=''" v-model="item.checked"></el-checkbox></div>
							</td>

						</tr>
						<tr class="nodata" v-if="!tableData2 || tableData2.length === 0">
							<td colspan="8"> 暂无数据</td>
						</tr>
					</tbody>
				</table>

				<div class="flexX  pageBox" :class=" total2>pageSize2?'mt10':''">
					<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange2" @current-change="handleCurrentChange2"
					 :current-page="page2" :page-sizes="pageSizesArr2" :page-size="pageSize2" layout="total, sizes, prev, pager, next, jumper"
					 :total="total2"></el-pagination>
				</div>
			</div>
			<div class="flex1">

				<table class="dataTable" width="100%" v-loading="loading3">
					<thead>
						<tr>
							<th colspan="7" class="textCenter">
								<span class="mr20">商家已绑定卡</span>
								<el-button size="mini" type="primary" class="mr20" @click="cutCard()">减卡</el-button>
								<span class="mr20">总数：{{cardCountData.total_count}}</span>
								<span class="mr20"> 启用：{{cardCountData.enable_count}}</span>
								<span class="mr20">监控在线：{{cardCountData.online_count}}</span>
								<span>短信在线：{{cardCountData.tele_online_count}}</span>
							</th>
						</tr>
						<tr>
							<th colspan="7" class="textCenter">
								<span class="mr20">筛选:</span>
								<el-input class="mr20" size="mini" style="width: 180px;" placeholder="请输入姓名" v-model="name"></el-input>
								<el-button size="mini" type="primary" :icon="disLoading3?'el-icon-loading':'el-icon-search'" @click="getDatatable3" :disabled="disLoading3">搜索</el-button>
								
							</th>
						</tr>
						<tr>
							<th class="textCenter" style="width: 100px;">搬砖工</th>
							<th class="textCenter" style="width: 100px;">持卡人</th>
							<th class="textCenter">短信在线状态</th>
							<th class="textCenter">监控状态</th>
							<th class="textCenter" style="width: 80px;">停启</th>
							<th class="textCenter">卡余额</th>
							<th class="textCenter" style="width: 60px;">操作</th>

						</tr>
					</thead>
					<tbody :style="`max-height: ${height3}px;`">
						<tr v-for="item in tableData3" v-if="(tableData3 && tableData3.length > 0)">
							<td class="textCenter" style="width: 100px;">{{item.name}}</td>
							<td class="textCenter" style="width: 100px;">{{item.true_name}} <br>{{item.card.substring(0,3)}}***{{item.card.substring(item.card.length-4,item.card.length)}} <br> {{item.bank}}</td>
							<td class="textCenter">
								<span v-if="item.phone_type == 2" :class="item.telephone_is_online == 1?'csuccess':item.telephone_is_online == 0?'cwarning':'cdanger'">{{item.telephone_is_online == 1?'在线':item.telephone_is_online == 0?'离线':'掉线'}}</span>
							</td>
							<td class="textCenter"><span :class="item.is_online == 1?'csuccess':item.is_online == 0?'cwarning':'cdanger'">{{item.is_online == 1?'在线':item.is_online == 0?'离线':'掉线'}}</span></td>
							<td class="textCenter" style="width: 80px;">
								<div class="whiteSpaceNowrap">
									<span :class="item.state == 0?'csuccess':'cdanger'">{{item.state == 0?'开启 ':'停用 '}}</span><br>
									<el-button size="mini" @click="cardToggle(item)">{{item.state == 1?'开启':'停用'}}</el-button>
								</div>
							</td>
							<td class="textCenter">{{item.true_quota | tofixed}}</td>
							<td class="textCenter" style="width: 60px;">
								<el-checkbox v-model="item.checked"></el-checkbox>
							</td>
							<!-- <td colspan="6" v-if="!(item.true_name.includes(name) || name == '')"> 暂无数据</td> -->
						</tr>
						<tr class="nodata" v-if="!tableData3 || tableData3.length === 0">
							<td colspan="7"> 暂无数据</td>
						</tr>
					</tbody>
				</table>
				<div class="flexX  pageBox" :class="total3>pageSize3?'mt10':''">
					<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange3" @current-change="handleCurrentChange3"
					 :current-page="page3" :page-sizes="pageSizesArr3" :page-size="pageSize3" layout="total, sizes, prev, pager, next, jumper"
					 :total="total3"></el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		isEmpty,
		isPositiveNumber,
		isMoney
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	export default {
		data() {
			return {
				disLoading: false,
				loading1: false,
				loading2: false,
				loading3: false,
				tableData1: [],
				tableData2: [],
				tableData3: [],
				business_no: '',
				trader_id: '',
				business_name: '',
				name: '',
				pageSizesArr1: [20, 50, 100, 200],
				pageSize1:20,
				total1: 0,
				page1: 1,
				pageSizesArr2: [20, 50, 100, 200],
				pageSize2: 20,
				total2: 0,
				page2: 1,
				pageSizesArr3: [20, 50, 100, 200],
				pageSize3: 20,
				total3: 0,
				page3: 1,
				carder_id: '',
				checkedArr: [],
				options: [],
				carderItem: {},
				cardCountData: {},
				disLoading3:false,
				height:0,
				height2:0,
				height3:0,
				// 可用卡搜索
				cardName: '',
			}
		},
		created() {
			this.business_no = this.$route.query.business_no
			this.trader_id = this.$route.query.trader_id
			this.cardCount()
			this.traderCardCard()
			this.carderList()
			this.treeList()
			this.$nextTick(() => {
				let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
				let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
				let documentHeight = document.documentElement.clientHeight
				this.height = documentHeight - topNav - 20 - pageBox - 40 - 62 - 20
				console.log(this.height)
			})
			this.$nextTick(() => {
				let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
				let pageBox = document.getElementsByClassName('pageBox')[1].offsetHeight;
				let documentHeight = document.documentElement.clientHeight
				this.height2 = documentHeight - topNav - 20 - pageBox - 40 - 106 - 20
				console.log(this.height2)
			})
			this.$nextTick(() => {
				let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
				let pageBox = document.getElementsByClassName('pageBox')[2].offsetHeight;
				let documentHeight = document.documentElement.clientHeight
				this.height3 = documentHeight - topNav - 20 - pageBox - 40 - 157 - 20
				console.log(this.height3)
			})
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					// return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
					let val =Math.floor(((value * 1)*100).toPrecision(12))
					return ((val/100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			//统计
			cardCount() {
				let data = {
					business_no: this.business_no
				}
				this.$api.cardCount(data).then(res => {
					console.log(res.data)
					if (res.status == 1) {
						this.cardCountData = res.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			//选择码商 2
			chooseCarder(item) {
				this.carderItem = item
				this.cardList()
			},
			getDatatable4(){
				this.page2 = 1;
				this.cardList()
			},
			//tableData2
			cardList() {
				let data = {
					carder_id: this.carderItem.id,
					page: this.page2,
					size: this.pageSize2,
					card_name: this.cardName,
				}
				// this.loading2 = true
				this.$api.cardList(data).then(res => {
					this.loading2 = false;
					if (res.status === 1) {
						this.tableData2 = res.data.data.map(item2 => {
							item2.checked = false
							return item2;
						});
						this.total2 = res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.loading2 = false
				})
			},
			//选择搬砖工
			agentChang(value) {
				console.log(value)
				this.carder_id = value
				this.getData()
				console.log('this.val', this.carder_id)
			},
			treeList() {
				this.$api.treeList({}).then(res => {
					if (res.status === 1) {
						this.options = res.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			//查询按钮
			getData() {
				this.page1 = 1
				this.page2 = 1
				this.page3 = 1
				this.carderList()
				this.traderCardCard()
				this.tableData2 = []
			},
			gotopage(item) {

			},
			getDatatable3(){
				this.page3 = 1;
				this.traderCardCard()
			},
			//商家已绑卡列表  3
			traderCardCard() {
				let data = {
					business_no: this.business_no,
					name: this.name,
					page: this.page3,
					size: this.pageSize3
				}
				// this.loading3 = true;
				this.disLoading3 = true
				this.$api.traderCardCard(data).then(res => {
					this.disLoading3 = false
					this.loading3 = false
					if (res.status == 1) {
						this.tableData3 = res.data.data.map(item => {
							item.checked = false
							return item
						})
						this.total3 = res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.loading3 = false
				})
			},
			//码商列表
			carderList() {
				let data = {
					carder_id: this.carder_id,
					name: this.name,
					page: this.page1,
					size: this.pageSize1
				}
				// this.loading1 = true;
				this.disLoading = true;
				this.$api.carderList(data).then(res => {
					this.loading1 = false
					this.disLoading = false
					if (res.status == 1) {
						this.tableData1 = res.data.data
						this.total1 = res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.loading1 = false
					this.disLoading = false
				})
			},
			//启用 停用
			cardToggle(item) {
				let data = {
					card_id: item.id,
					state: item.state == 1 ? 0 : 1,
				}

				this.$confirm(`确认${item.state==1?'启用':'停用'}${item.card}吗?`, `提示`, {
					confirmButtonText: `确认`,
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					// this.loading = true;
					this.$api.cardToggle(data).then(res => {
						this.loading = false;
						if (res.status === 1) {
							this.traderCardCard();
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					}).catch(error => {
						this.loading = false;
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: `已取消${item.state==1?'启用':'停用'}账号`
					});
				});
			},
			//加卡
			addCard() {
				this.checkedArr = this.tableData2.filter(item => {
					if (item.checked) {
						return item
					}
				}).map(item => {
					return item.id
				});
				console.log(this.checkedArr)
				this.bindCard('IN')
			}, //加卡
			cutCard() {
				this.checkedArr = this.tableData3.filter(item => {
					if (item.checked) {
						return item
					}
				}).map(item => {
					return item.id
				});
				console.log(this.checkedArr)
				this.bindCard('OUT')
			},
			//绑卡 / 解绑
			bindCard(type) {
				if (this.checkedArr.length == 0) {
					this.$message({
						type: 'error',
						message: '请选择需要操作的卡'
					})
					return
				}
				let data = {
					card_id: JSON.stringify(this.checkedArr),
					trader_id: this.trader_id,
					type: type
				}
				if(type == 'IN'){
					this.loading2 = true;
				}else if(type == 'OUT'){
					this.loading3 = true;
				}
				
				this.$api.bindCard(data).then(res => {
					if(type == 'IN'){
						this.loading2 = false;
					}else if(type == 'OUT'){
						this.loading3 = false;
					}
					if (res.status == 1) {
						this.$message({
							type: 'success',
							message: `${type=='IN'?'加卡成功':'减卡成功'}`
						})
						this.cardCount()
						this.cardList()
						this.traderCardCard()
						this.carderList()


					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					if(type == 'IN'){
						this.loading2 = false;
					}else if(type == 'OUT'){
						this.loading3 = false;
					}
				})
				console.log(data)
			},
			handleSizeChange1(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize1 = val
				this.page1=1
				this.carderList()
				// this.payTypeAll()
			},
			handleCurrentChange1(val) {
				// console.log(`当前页: ${val}`);
				this.page1 = val
				this.carderList()
				// this.payTypeAll()
			},
			handleSizeChange2(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize2 = val
				this.page2 = 1
				this.cardList()
				// this.payTypeAll()
			},
			handleCurrentChange2(val) {
				// console.log(`当前页: ${val}`);
				this.page2 = val
				this.cardList()
				// this.payTypeAll()
			},
			handleSizeChange3(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize3 = val
				this.page3 = 1
				// this.payTypeAll()
				this.traderCardCard()
			},
			handleCurrentChange3(val) {
				// console.log(`当前页: ${val}`);
				this.page3 = val
				this.traderCardCard()
			},

		},
		watch: {
			total1(val, old) {
				this.$nextTick(() => {
					let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
					let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
					let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
					let documentHeight = document.documentElement.clientHeight
					this.height = documentHeight - topNav - 20 - pageBox - 40 - 62 - 20
					console.log(this.height)
				})
			},
			total2(val, old) {
				this.$nextTick(() => {
					let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
					let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
					let pageBox = document.getElementsByClassName('pageBox')[1].offsetHeight;
					let documentHeight = document.documentElement.clientHeight
					this.height2 = documentHeight - topNav - 20 - pageBox - 40 - 106 - 20
					console.log(this.height2)
				})
			},
			total3(val, old) {
				this.$nextTick(() => {
					let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
					let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
					let pageBox = document.getElementsByClassName('pageBox')[2].offsetHeight;
					let documentHeight = document.documentElement.clientHeight
					this.height3 = documentHeight - topNav - 20 - pageBox - 40 - 157 - 20
					console.log(this.height3)
				})
			},
		},
	}
</script>

<style lang="scss" scoped="scoped">
	/* 滚动条宽度 */
	  ::-webkit-scrollbar {
	       width: 0px;
	   }
	   table tbody {
	       display: block;
	       overflow-y: auto;
	       -webkit-overflow-scrolling: touch;
			border-bottom: solid 1px #ddd;
	   }	 
	   table tbody tr:last-child td{
			border-bottom: none;
	   }	 
	   table thead tr, table tbody tr, table tfoot tr {
	       box-sizing: border-box;
	       table-layout: fixed;
	       display: table;
	       width: 100%;
	   }
	   table td{
		   word-break:break-all;
	   }
</style>
